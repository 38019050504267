import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'

class ElectricGates extends React.Component {
	render() {
		const siteTitle =
			'DFW Electric Gate Installation & Repair - Arlington Electric Gates & Fences'
		const siteDescription =
			'Want an electric gate in the Dallas-Fort Worth and Arlington area? Call  For automatic custom electric gates, contact us today!'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Electric Gates & Fences</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row">
						<div className="col-md-12">
							<p>
								Are you looking for an electric gate in Dallas and Fort Worth,
								TX? When you need excellent security for your property, choose
								an electric gate or fence from J & J Gates Service and Design.
								We install a wide variety of gates to improve the security and
								appearance of your home or business. With a variety of gate
								systems and designs to choose from, there is sure to be an
								option that suits your needs and budget. Call{' '}
								<Link to="/">J & J Gates Service and Design</Link> at to learn
								more!
							</p>
							<p>
								Electronic gates are specially designed to warn away animals and
								humans that get too close to secure areas. This type of gate and
								fence are used to improve the security of a perimeter, typically
								by farms and ranches, or for high-security applications. As the
								leading gate company in Dallas-Fort Worth, J & J Gates Service
								and Design have over 12 years of experience installing,
								repairing, and programming electric gates and fences.
							</p>
							<p>
								If you want an electric gate and fence for your home, business,
								farm, or ranch, we’ll work with you to select the best option.
								Options include{' '}
								<Link to="/gates/services/gate-automation/">
									automatic gate openers
								</Link>
								, keypads, motion sensors, and more. Improve the security of
								your property with an electric gate and fence. We have the
								experience needed and install and repair quality access control
								systems from Apollo, LiftMaster, and Elite.
							</p>
						</div>
						<div className="col-md-12">
							<h4>Installation and Repair</h4>
							<p>
								While{' '}
								<Link to="/gates/services/gate-installation/">installing</Link>{' '}
								or <Link to="/gates/services/gate-repair/">repairing</Link> an
								electric fence or gate can be tricky, our technicians and
								designers are incredibly skilled, and you can rest assured that
								it will be efficient, worry-free, and on time. We follow
								important safety policies and ensure that the electricity works
								properly and the design looks good. You can rely on J & J Gates
								Service and Design for professional, quality electric gate
								service in the Arlington or DFW area.{' '}
								<strong>
									<em>
										Call us today at if you have questions about our services!
									</em>
								</strong>
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ElectricGates

export const ElectricGatesPageQuery = graphql`
	query ElectricGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
